import { useContext, useEffect, useRef, useState } from 'react';
import { useImmer } from "use-immer";
import DataTable from 'components/DataTable';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import numeral from "numeral";
import _ from 'lodash';
import { useYADialog } from "components/YADialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MDBadge from 'components/MDBadge';
import DashboardItem from 'components/DashboardItem';
import { CubeContext } from '@cubejs-client/react';
import ErrorBox from 'components/ErrorBox';
// import ColumnOptions from './components/ColumnOptions';
import moment from 'moment';
import SubChartRenderer from './components/SubChartRenderer';
import * as XLSX from 'xlsx';
import { getDrilldownPath, removeSessionFilter, sanitizeCell } from 'utils';
import { useAppController } from 'context';
import { Icon } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import { normalizeCurrency } from 'utils/table';
import { parseTableColumnValue } from 'utils/charts';
import fetchRequest from "utils/fetchRequest";
import Tooltip from '@mui/material/Tooltip';
import { addFilters } from '..';
import S3bucketForm from 'components/S3bucketForm';
import CapsuleText from 'components/CapsuleText';
const getValue = (row, propName) => row.values[propName.replace(/\./g, "__")];

const updateQuery = (query) => {
  const defaultDimensions = [
    "SpendCube.id",
    "ExpenseTypes.name",
    "Accounts.code",
    "CostCentres.code",
    "Vendors.code",
    "CostPools.costPool",
    "SubCostPools.subCostPool",
    "Towers.tower",
    "SubTowers.subTower",
    "SolutionTypes.solutionType",
    "SolutionCategories.solutionCategory",
    "SolutionNames.solutionName",
    "SolutionOfferings.solutionOfferingCode",
    "BusinessUnits.code"
  ]

  const defaultMeasures = [ "SpendCube.totalSpend" ]
  defaultDimensions.map( dimensions => {
    if(!_.includes(query.dimensions, dimensions)) query.dimensions.push(dimensions)
  })
  defaultMeasures.map( measures => {
    if(!_.includes(query.measures, measures)) query.dimensions.push(measures)
  })
return query
}
  
const loadQueryResult = async (cubeApi, query, pagingOptions, disableServerSidePaging) => {
  let newQuery = { ...query };
  if (pagingOptions) {
    const { pageSize, currentPage, selectedColumns } = pagingOptions;
    newQuery.dimensions = newQuery.dimensions.filter(d => selectedColumns.includes(d));
    newQuery.measures = newQuery.measures.filter(m => selectedColumns.includes(m));
    if (!disableServerSidePaging) {
      newQuery["limit"] = pageSize + 1;
      newQuery["offset"] = currentPage === 0 ? 0 : (currentPage * pageSize);
    }
  }
  try {
    const response = await cubeApi.load(newQuery);
    return {
      resultSet: response,
      error: null
    };
  } catch (error) {
    return {
      resultSet: null,
      error
    };
  }
}

const defaultSelector = (columns) => {
  const selector = [
    {title: "Transaction Date", dataType: "date"},
    {title: "Journal ID", dataType: "textbox"},
    {title: "Journal Line", dataType: "string"},
    {title: "Expense Type", dataType: "textbox"},
    {title: "Account Code", dataType: "textbox"},
    {title: "Account Name", dataType: "textbox"},
    {title: "Cost Center Code", dataType: "textbox"},
    {title: "Cost Center Name", dataType: "textbox"},
    {title: "Vendor Code", dataType: "textbox"},
    {title: "Vendor Name", dataType: "textbox"},
    {title: "Cost Pool", dataType: "textbox"},
    {title: "Sub Cost Pool", dataType: "textbox"},
    {title: "Tower", dataType: "textbox"},
    {title: "Sub Tower", dataType: "textbox"},
    {title: "Solution Type", dataType: "textbox"},
    {title: "Solution Category", dataType: "textbox"},
    {title: "Solution Name", dataType: "textbox"},
    {title: "Solution Offering ID", dataType: "textbox"},
    {title: "Solution Offering", dataType: "textbox"},
    {title: "Business Unit ID", dataType: "textbox"},
    {title: "Business Unit", dataType: "textbox"},
    {title: "BU Cost Centre", dataType: "textbox"},
    {title: "Unit Of Measure", dataType: "textbox"},
    {title: "Unit Spend", dataType: "currency"},
    {title: "Usage", dataType: "number"},
    {title: "Spend", dataType: "currency"},
  ]
  columns.map((column) => {
    const found = _.filter(selector, {title: column.title})
    if (found) {
      found.map((object) => {
        Object.keys(object).map((key) => {
          column[key] = object[key]
        })
      })
    }
  })
}

const TableRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions, action, BUSpend, monthFilterName, yearFilterName, monthFilter, yearFilter, setRefresh, setAction, status, approver, reloadData, chargeBackReload }) => {
  const { reportId } = useParams();
  const { showAlert, showPrompt, showSnackbar } = useYADialog();
  defaultSelector(vizOptions.columns);
  const columnsDef = vizOptions.columns
  const selectedColumnsInitial = columnsDef?.filter((c) => !c.optional).map((c) => c.name);
  const mounted = useRef(false);
  const { cubeApi } = useContext(CubeContext);
  const [result, setResult] = useState(null);
  const [pageOptions, setPageOptions] = useState({ selectedColumns: selectedColumnsInitial, currentPage: 0, pageSize: vizOptions.defaultPageSize || 50 });
  const [pgIndx, setPgIndx] = useState(0)
  const [widgetName, setWidgetName] = useState('');
  const [search, setSearch] = useState(null);
  const [chargeBackApprove, setChargeBackApprove] = useState(false);
  const [chargeBackAdd, setChargeBackAdd] = useState(false);
  let [hidefooter, setHidefooter] = useState(false)
  const [filtersState, setFiltersState] = useImmer({
    globalFilter: undefined,
    filters: [],
  });

  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState((draft) => {
        draft.globalFilter = latestGlobalFilter;
        draft.filters = latestFilters;
    });
  };
  const onPageOptionsChange = (pageOptionsVal) => {
    setPageOptions(prev => ({ ...prev, ...pageOptionsVal }));
  };

  const handleApproveChargeBack = () => {
    showPrompt("Approve Chargeback", "Do you want to Approve the Chargeback" + " for - [ " + monthFilterName + " " + yearFilterName + " ]", async () => {
      setChargeBackApprove(true)
      let [err, data] = await fetchRequest.post(`/api/dataflow/approveChargeBack/${yearFilter}/${monthFilter}`)
      if (err) {
        setChargeBackApprove(false)
        console.error(err)
        showAlert("Approve Chargeback", "Something went wrong. Contact your administrator.");
      }
      else if (data) {
        setChargeBackApprove(false)
        showSnackbar(data, "success")
        setAction(null)
        reloadData()
        chargeBackReload()
        setRefresh(Math.random())
      }
    });
  }

  const handleAddChargeBack = async () => {
    showPrompt("Create Chargeback", "Chargeback will be created" + " for - [ " + monthFilterName + " " + yearFilterName + " ]", async () => {
      setChargeBackAdd(true)
      const vizStateCopy = _.cloneDeep(vizState)
      const fullQuery = addFilters(updateQuery(vizStateCopy.query), yearFilterName, monthFilterName);
      const fullResult = await loadQueryResult(cubeApi, fullQuery, null, vizOptions.disableServerSidePaging);
      const resultData = fullResult?.resultSet.loadResponse.results[0].data
      let [err, data] = await fetchRequest.post(`/api/dataflow/addChargeBack/${yearFilter}/${monthFilter}`, {vizState, vizOptions, resultData})
      if (err) {
        setChargeBackAdd(false)
        console.error(err)
        showAlert("Create Chargeback", "Something went wrong. Contact your administrator.");
      }
      else if (data) {
        setChargeBackAdd(false)
        setChargeBackApprove(false)
        showSnackbar(data, "success")
        setAction(null)
        reloadData()
        chargeBackReload()
        setRefresh(Math.random())
      }
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let widgetRef = reportId + '-' + title.toLowerCase().replaceAll(' ', '-');
      setWidgetName(widgetRef);
      if (widgetRef.indexOf("undefined") === -1 && sessionStorage[widgetRef]) {
        onPageOptionsChange({ selectedColumns: sessionStorage[widgetRef].split(',') });
      }
      setResult(null);
      const result = await loadQueryResult(cubeApi, addFilters(vizState.query, yearFilterName, monthFilterName), null, vizOptions.disableServerSidePaging);
      setResult(result);
    };
  
    if (!mounted.current) {
      mounted.current = true;
      fetchData();
    }
  }, [pageOptions, vizState, yearFilter]);

  const loading = !result;

  if (result?.error) {
    return <ErrorBox error={result?.error} />
  }

  if (loading)
    return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

  return <TableRenderer1 {...({ title, subtitle, filtersState, handleOnFiltersStateUpdate, action, BUSpend, handleAddChargeBack, chargeBackAdd, resultSet: result.resultSet, error: result.error, chartHelpContextKey, vizState, vizOptions, pageOptions, onPageOptionsChange, widgetName, setPgIndx, pgIndx, search, setSearch, setPageOptions, selectedColumnsInitial ,hidefooter, setHidefooter, yearFilter, yearFilterName, monthFilter,monthFilterName, status,setRefresh, handleApproveChargeBack, chargeBackApprove, approver })} />
}

// const TableRenderer1 = ({ title, subtitle, action, filtersState, handleOnFiltersStateUpdate, BUSpend, handleAddChargeBack, resultSet, error, vizState, vizOptions, pageOptions, onPageOptionsChange, widgetName, setPgIndx, search, setSearch, setPageOptions, selectedColumnsInitial, yearFilterName, monthFilter, status,setRefresh, handleApproveChargeBack }) => {
  const TableRenderer1 = ({ title, subtitle, action, filtersState, handleOnFiltersStateUpdate, BUSpend, handleAddChargeBack, chargeBackAdd, resultSet, error, vizState, vizOptions, pageOptions, onPageOptionsChange, setPgIndx, search, setSearch, setPageOptions, selectedColumnsInitial, monthFilterName, yearFilterName, monthFilter, status,setRefresh, handleApproveChargeBack, chargeBackApprove, approver }) => {
  // const { heightUnits, columns: columnsDef } = vizOptions;
  const { columns: columnsDef } = vizOptions;
  const { showReport,showCustomForm } = useYADialog();
  let navigate = useNavigate()
  let location = useLocation()

  const [controller,] = useAppController();
  const { userInfo, appDef: { settings, years } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";

  // const [showOptions, setShowOptions] = useState(false);

  const queryColumns = columnsDef?.filter(c => pageOptions.selectedColumns?.includes(c.name));

  const subChartColumns = columnsDef?.filter((c) => c.dataType === "graph");
  const hasSubCharts = subChartColumns && subChartColumns.length > 0;
  const [ queryTotal, setQueryTotal ] =useState(0)

  useEffect(()=>{
   setPgIndx(null)
  },[resultSet.loadResponse.pivotQuery.filters])
  
  const loading = !resultSet;
  const subQueriesLoading = hasSubCharts

  if (error) {
    return <ErrorBox error={error} />
  }

  if (loading || subQueriesLoading)
    return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>

  let currentFilters
  currentFilters = removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions)

  // const cardHeight = heightUnits ? (heightUnits * 56) + ((heightUnits - 1) * 8) : null;

  // const containerMaxHeight = cardHeight ? cardHeight - 176 : null;

  const columns = queryColumns?.filter((col) => !col.hidden).map(
    (c) => ({
      Header: c.title,
      dataType: c.dataType,
      accessor: c.name.replace(/\./g, "__"),
      align: (c.dataType === "number" || c.dataType === "currency" || c.dataType === "variance" || c.dataType === "graph") ? "right" : c.align || "left",
      disableSorting: (c.dataType === "graph") ? true : c.disableSorting || false,
      Cell: ({ row: { original }, cell: { value } }) => {
        if (c.dataType === "number") {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{numeral(normalizeCurrency(value)).format('0,0')}</MDTypography>
        }
        else if (c.dataType === "currency") {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography>
        }
        else if (c.tooltip) {
          return <Tooltip title = {value} placement="bottom"><MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value}</MDTypography></Tooltip>
        }
        else if (c.dataType === "date")
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value ? moment(value).format(defaultDateFormat || c.format) : ""}</MDTypography>
        else if (c.dataType === "boolean")
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value === true ? "Yes" : "No"}</MDTypography>
        else if (c.dataType === "variance") {
          if (c.variant === "dot")
            return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"} display="flex" alignItems="center" justifyContent="flex-end">{numeral(!value ? 0 : normalizeCurrency(value)).format('$0,0')}<MDBox ml={0.8} sx={({ palette: { success, error } }) => ({ height: 10, width: 10, borderRadius: "50%", backgroundColor: Number(value) <= 0 ? success.main : error.main })}></MDBox></MDTypography>
          else if (c.variant === "text")
            return <>{Number(value) <= 0 ? <Icon color="success">arrow_downward</Icon> : <Icon color="error">arrow_upward</Icon>} &nbsp;&nbsp;<MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{numeral(!value ? 0 : normalizeCurrency(value)).format('$0,0')}</MDTypography></>
          return <MDBadge container circular key={c.name.replace(/\./g, "__")} badgeContent={numeral(normalizeCurrency(value)).format('$0,0')} color={Number(value) <= 0 ? "success" : "error"} variant="gradient" size="xs" />
        }
        else if (c.dataType === "calculated") {
          const result = parseTableColumnValue(c, original);
          if (c.trendColumn) {
            let trendValueDirectionUpVal = (result.originalValue && result.originalValue > 0);
            const trendColor = c.trendColumn.negateColorLogic ?
              (trendValueDirectionUpVal ? "error" : "success")
              :
              (trendValueDirectionUpVal ? "success" : "error");

            return <>
              {result.originalValue !== null && <Icon color={trendColor} sx={{ mx: .5, lineHeight: "18px" }}>{trendValueDirectionUpVal ? 'arrow_upward' : 'arrow_downward'}</Icon>}
              <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color={"dark"} fontWeight={c.emphasize && "medium"}>
                {result.value}
              </MDTypography>
            </>;
          }
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color={"dark"} fontWeight={c.emphasize && "medium"}>{result.value}</MDTypography>
        }
        else if (c.dataType === "graph") {
          const { filteredRow, data: { resultSet } } = value;
          const { vizState: { chartType }, vizOptions } = c;
          return resultSet ? <SubChartRenderer key={c.name.replace(/\./g, "__")} chartType={chartType} tableRow={filteredRow} resultSet={resultSet} vizOptions={vizOptions} /> : <></>
        }
        if (c.avatar) {
          return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" display="flex" color="dark" alignItems="center" justifyContent="flex-start" fontWeight={c.emphasize && "medium"}><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
        }
        return <MDTypography key={c.name.replace(/\./g, "__")} variant="caption" color="dark" fontWeight={c.emphasize && "medium"}>{value}</MDTypography>
      }

    })
  );

  let i = 1;
  let rows = [];
  rows = resultSet?.tablePivot().map((row) => {
    if (i === 1 && vizOptions.changeMonths) {
      let months = row["Months.allMonths"].split(",")
      columns.splice(columns.findIndex(item => item.Header == "Months"), 1);
      months.map((mth, idx) => {
        columns[columns.findIndex(item => item.Header == idx + 1)].Header = mth;
      });
      i = 0;
    }
    let r = {};
    resultSet.tableColumns().forEach((c) => {
      if (c.key !== queryColumns?.filter((col) => col.hidden).name)
        r[c.key.replace(/\./g, "__")] = row[c.key];
    });
    return r;
  });

  const _onRowClick = async (e, row) => {
    // reverted to fix issue (CT-1714) to be tested further - KS pls retest this block
    const currentFilterNames = currentFilters.map(f => f.name);
    var obj = Object.assign([], [...currentFilters]);
    vizOptions.params.filter((col) => pageOptions.selectedColumns?.includes(col) && currentFilterNames?.includes(col)).forEach((col) => _.remove(obj, { name: col }))
    vizOptions.params.filter((col) => pageOptions.selectedColumns?.includes(col)).forEach((col) => obj.push({ "name": col, "values": [getValue(row, col)] }))
    if (vizOptions["popupTo"] && row.values["Months__month"]) {
      if (obj.find((({ name }) => name === "Months.month"))) {
        _.remove(obj, { name: "Months.month" })
        obj.push({ name: "Months.month", "values": [row.values["Months__month"]] })
      }
    }
    const replaceEmailId = (email)=> {
      const [, domain] = email.split('@')
      let userNames = `#@${domain}`;
      return userNames 
    }
    let url = ""
    if(vizOptions.url) {
      const [error, users] = await fetchRequest.get(`/api/users/lookup/user`);
      if(error) console.error(Error);
      let user = users?.find(item => item. id === userInfo.sub)
      let userName = replaceEmailId(user?.email)
      let urlValue = obj[obj.length-1]["values"];
      let providerDomain = obj[obj.length-2]["values"][0];
      url = `${providerDomain}${userName}/resource${urlValue}`
      if(providerDomain)
      window.open(url)
    }
    var popupkey = ""
    var popupTo = vizOptions["popupTo"] ? vizOptions["popupTo"] : ''
    if (vizOptions["popupToKey"]) {
        popupkey = obj.find((({ name }) => name === vizOptions["popupToKey"])) ? obj.find((({ name }) => name === vizOptions["popupToKey"])).values[0] : ''
        popupTo =  vizOptions["popupTo"] ? vizOptions["popupTo"].replace("changeme",popupkey.toLowerCase()).replace(' ','') : ''
    }
    if (vizOptions.queryType && (vizOptions.queryType === "CompareWithPrevYearTrend" || vizOptions.queryType === "CompareWithPrevYearTrendFiltered") && vizOptions["popupTo"] && vizOptions["popupTo"] !== "") {
      if (obj.find((({ name }) => name === "Years.year")))
        _.remove(obj, { name: "Years.year" })
      obj.unshift({ name: "Years.year", "values": [sessionStorage["Year"]] })
    }
    if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
      vizOptions.excludeFilters.map((fil) => {
        if (obj.find((({ name }) => name === fil)))
          _.remove(obj, { name: fil })
      })
    }
    vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj })
    vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && (vizOptions["popupToKey"] ? showReport(popupTo, obj, null) : showReport(vizOptions["popupTo"], obj, null));
  };

  const bindOnClick = vizOptions["drillTo"] || vizOptions["popupTo"] || vizOptions["url"];

  // const handleOnOptionsClick = () => {
  //   setShowOptions(true);
  // }
  // const handleOnOptionsClose = () => {
  //   setShowOptions(false);
  // }

  // const handleOnOptionsApply = (selectedColumnIds) => {
  //   if (setPgIndx) {
  //     setPgIndx(null)
  //   }
  //   onPageOptionsChange({ ...pageOptions, selectedColumns: selectedColumnIds })
  // }


  function getYear(yearFilterName) {
    const startDate = _.find(years, {name: yearFilterName})?.startDate;
    const yearSplit = yearFilterName?.split('-').length > 1 ? yearFilterName.split('-').map(year => { 
      if (Number(year) < 100) {
        const currCentury = Number(Math.round(moment(Date.now()).year()/100))
        year = (currCentury * 100) + Number(year)
        return Number(year)
      }else {
        return Number(year)
      }
     }) : Number(yearFilterName.match(/\d+/g))
    if (Array.isArray(yearSplit)) {
      if (monthFilter >= Number(moment(startDate || '').month() + 1)) {
        return yearSplit[0]
      } else {
        return yearSplit[1]
      }
    } else {
      const dateFormat1 = Number((String(yearSplit) + (monthFilter < 10 ? `0` + monthFilter : monthFilter)))
      const dateFormat2 = moment(startDate || '').year() * 100 + moment(startDate || '').month() + 1;
      if (dateFormat1 >= dateFormat2) {
        return yearSplit
      } else {
        return yearSplit + 1
      }
    }
  }

  const startDate = moment(new Date(getYear(yearFilterName), monthFilter - 1, 1)).format(defaultDateFormat);
  const endDate = moment(new Date(getYear(yearFilterName), monthFilter, 0)).format(defaultDateFormat);
  
  const handleCsvExport = async () => {
    if (columns && rows) {
      var data = [];
      rows.forEach(element => {
        let obj = {}
        columns.forEach((e) => {
          if (e.dataType === 'date' && element[e.accessor] !== null) {
            element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
          } else {
            if (e.dataType === 'calcStartDate') {
              element[e.accessor] = startDate;
            }
            if (e.dataType === 'calcEndDate') {
              element[e.accessor] = endDate;
            }
          }
          if(element[e.accessor]){
            element[e.accessor] = sanitizeCell(element[e.accessor])
          }
          obj[e.Header] = element[e.accessor]
        })
        data.push(obj)
      });
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, 'test')
      XLSX.writeFile(wb, `Chargeback_${status}_${getYear(yearFilterName)}_${monthFilter < 10 ? `0${monthFilter}`: monthFilter}.csv`)
      await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Chargeback for ${yearFilterName} - ${monthFilterName}` });
    }
  }
  const handleS3Export = async () => {
    if (columns && rows) {
      var data = [];
      rows.forEach(element => {
        let obj = {}
        columns.forEach((e) => {
          if (e.dataType === 'date' && element[e.accessor] !== null) {
            element[e.accessor] = moment(element[e.accessor]).format(defaultDateFormat);
          } else {
            if (e.dataType === 'calcStartDate') {
              element[e.accessor] = startDate;
            }
            if (e.dataType === 'calcEndDate') {
              element[e.accessor] = endDate;
            }
          }
          obj[e.Header] = element[e.accessor]
        })
        data.push(obj)
      });
      const fileName = `yarken_chargeback_${moment(Date()).format("YYYYMMDDHHmmss")}.xlsx`
         showCustomForm("Choose S3 Bucket", () => <S3bucketForm onClose={handleClose} tableData = {data} fileName = {fileName} loading = {loading} />, null, null, null, 'sm');
         await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Exported Chargeback for ${yearFilterName} - ${monthFilterName} to S3 Bucket` });

    }
  }
  const handleClose = () => {
    setRefresh(Math.random())
  };

  const nodata = !rows?.length > 0;

  let handlePageOption = () => {
    setPageOptions({ selectedColumns: selectedColumnsInitial, currentPage: 0, pageSize: vizOptions.defaultPageSize || 50 })
  }

  const totalsRenderer = () => {
    return (
      <>
        {/* {action === 'view' && status &&<MDTypography display="inline-flex" mr="100px" variant="h5" sx={{fontWeight: 300}}>&nbsp;Status: <MDTypography variant="h5" sx={{fontWeight: 400}} color={status === 'APPROVED' ? 'success' : "info"}>&nbsp;{status}</MDTypography></MDTypography>} */}
        {action === 'view' && status && <CapsuleText leftSideText={"Status "} rightSideText={status} colorText={status === 'APPROVED' ? 'success' : "info"}/>}
          <CapsuleText leftSideText={"Total Business Unit "} rightSideText={numeral(normalizeCurrency(BUSpend)).format('$0,0')}/>
          <CapsuleText leftSideText={"Total Chargeback "} rightSideText={numeral(normalizeCurrency(queryTotal)).format('$0,0')} mr="100px"/>
        {/* <MDTypography display="inline-flex" mr="100px" variant="h5" sx={{fontWeight: 300}}>&nbsp;Total Business Unit : &nbsp;<MDTypography variant="h5" sx={{fontWeight: 400}}>{numeral(normalizeCurrency(BUSpend)).format('$0,0')}</MDTypography></MDTypography>
        <MDTypography display="inline-flex" mr="100px" variant="h5" sx={{fontWeight: 300}}>&nbsp;Total Chargeback : &nbsp;<MDTypography variant="h5" sx={{fontWeight: 400}}>{numeral(normalizeCurrency(queryTotal)).format('$0,0')}</MDTypography></MDTypography> */}
      </>
    )
  }
  var canDownload = true
  var isTable = vizState.chartType === "table"? true : false
  return <DashboardItem nodata={nodata} table title={''} download={canDownload} isTable={isTable} subtitle={''} primaryRender={false} onCsvExport={handleCsvExport} onS3Export = {handleS3Export} chargeBackStatus = {status} noLink={true}hideToggle={true} >
    <>
      <DataTable
        variant="tile"
        table={{ columns, rows }}
        containerMaxHeight={520}
        newStyle1={true}
        showTotalEntries={true}
        isSorted={true}
        noEndBorder
        primaryRender={totalsRenderer()}
        canSearch={true}
        onRowClick={bindOnClick && _onRowClick}
        // onOptionsClick={vizOptions.hideColumnOptions ? '' : handleOnOptionsClick}
        srch={search}
        filtersState={filtersState}
        onFiltersStateUpdate={handleOnFiltersStateUpdate}
        canFilter={true}
        setSrch={setSearch}
        onPageOptionsChange={onPageOptionsChange}
        handlePageOption={handlePageOption}
        createChargeBack={ action && action === 'preview' ? true : false}
        approveChargeBack={ action && approver && action === 'view' && status === 'DRAFT' ? true : false}
        onCreateChargeBack={handleAddChargeBack}
        chargeBackAdd={chargeBackAdd}
        chargeBackApprove={chargeBackApprove}
        onApproveChargeBack={handleApproveChargeBack}
        setQueryTotal={setQueryTotal}
        canTotal={'SpendCube__totalSpend'}
        dynamicTotal={false}
        entriesPerPage={true}
      >
      </DataTable>     
    </>
    {/* {showOptions && (
      <ColumnOptions
        columnsDef={columnsDef}
        selectedColumnsInitial={pageOptions.selectedColumns}
        widgetName={widgetName}
        onOptionsClose={handleOnOptionsClose}
        onOptionsApply={handleOnOptionsApply}
      />
    )} */}
  </DashboardItem>;
}

export default TableRenderer;