import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "components/YAForm/components/Dropdown";
import Textbox from "components/YAForm/components/Textbox";
import Axios from "axios";
import Switch from "components/YAForm/components/Switch";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import { useYADialog } from "components/YADialog";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import { applyVariables } from "utils/budget";
// import Integer from "components/YAForm/components/Integer";
import Float from "components/YAForm/components/Float";
import { getFiscalMonthsArray } from "utils/budget";
import { useIntl } from "react-intl";
import { toFloat } from "utils";
import numeral from "numeral";

const BudgetItemForm = (props) => {
    const { budgetId, costCenterView, headerDetails, pkId, mode, onClose } = props;
    const { showSnackbar } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [flag,setFlag] = useState(false)
    const domain = getDomain();
    let isDecimal = true

    const { watch, control, setValue, getValues, setError, clearErrors, formState: { errors, isSubmitting }, handleSubmit } = useForm();

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/budgets/budgetItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);
        const model = response?.data;

        const enableCpi = model?.enableCpi || false;
        const enableLpi = model?.enableLpi || false;

        setValue("budgetHeaderId", budgetId);
        setValue("cpi", headerDetails?.cpi || 0);
        setValue("lpi", headerDetails?.lpi || 0);
        setValue("expenseTypeId", response.data["expenseTypeId"]);
        setValue("costCentreId", response.data["costCentreId"]);
        setValue("accountId", response.data["accountId"]);
        setValue("vendorId", response.data["vendorId"]);
        setValue("costPoolId", response.data["costPoolId"]);
        setValue("subCostPoolId", response.data["subCostPoolId"]);
        setValue("towerId", response.data["towerId"]);
        setValue("subTowerId", response.data["subTowerId"]);
        setValue("solutionOfferingId", response.data["solutionOfferingId"]);
        setValue("businessUnitId", response.data["businessUnitId"]);
        setValue("enableCpi", enableCpi);
        setValue("enableLpi", enableLpi);
        setValue("jan", toFloat(model?.jan));
        setValue("feb", toFloat(model?.feb));
        setValue("mar", toFloat(model?.mar));
        setValue("apr", toFloat(model?.apr));
        setValue("may", toFloat(model?.may));
        setValue("jun", toFloat(model?.jun));
        setValue("jul", toFloat(model?.jul));
        setValue("aug", toFloat(model?.aug));
        setValue("sep", toFloat(model?.sep));
        setValue("oct", toFloat(model?.oct));
        setValue("nov", toFloat(model?.nov));
        setValue("dec", toFloat(model?.dec));
        setValue("janCal", model?.jan == null || model?.jan == 0 ? model?.jan : parseFloat(model?.jan).toFixed(2))
        setValue("febCal", model?.feb == null || model?.feb == 0 ? model?.feb : parseFloat(model?.feb).toFixed(2))
        setValue("marCal", model?.mar == null || model?.mar == 0 ? model?.mar : parseFloat(model?.mar).toFixed(2));
        setValue("aprCal", model?.apr == null || model?.apr == 0 ? model?.apr : parseFloat(model?.apr).toFixed(2));
        setValue("mayCal", model?.may == null || model?.may == 0 ? model?.may : parseFloat(model?.may).toFixed(2));
        setValue("junCal", model?.jun == null || model?.jun == 0 ? model?.jun : parseFloat(model?.jun).toFixed(2));
        setValue("julCal", model?.jul == null || model?.jul == 0 ? model?.jul : parseFloat(model?.jul).toFixed(2));
        setValue("augCal", model?.aug == null || model?.aug == 0 ? model?.aug : parseFloat(model?.aug).toFixed(2));
        setValue("sepCal", model?.sep == null || model?.sep == 0 ? model?.sep : parseFloat(model?.sep).toFixed(2));
        setValue("octCal", model?.oct == null || model?.oct == 0 ? model?.oct : parseFloat(model?.oct).toFixed(2));
        setValue("novCal", model?.nov == null || model?.nov == 0 ? model?.nov : parseFloat(model?.nov).toFixed(2))
        setValue("decCal", model?.dec == null || model?.dec == 0 ? model?.dec : parseFloat(model?.dec).toFixed(2));
        setValue("assumption", response.data["assumption"]);
        setLoading(false);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            setValue("budgetHeaderId", budgetId);
            setValue("cpi", headerDetails?.cpi || 0);
            setValue("lpi", headerDetails?.lpi || 0);
            setValue("expenseTypeId", null);
            setValue("costCentreId", null);
            setValue("accountId", null);
            setValue("vendorId", null);
            setValue("costPoolId", null);
            setValue("subCostPoolId", null);
            setValue("towerId", null);
            setValue("subTowerId", null);
            setValue("solutionOfferingId", null);
            setValue("businessUnitId", null);
            setValue("enableCpi", false);
            setValue("enableLpi", false);
            setValue("jan", null);
            setValue("feb", null);
            setValue("mar", null);
            setValue("apr", null);
            setValue("may", null);
            setValue("jun", null);
            setValue("jul", null);
            setValue("aug", null);
            setValue("sep", null);
            setValue("oct", null);
            setValue("nov", null);
            setValue("dec", null);
            setValue("janCal", null);
            setValue("febCal", null);
            setValue("marCal", null);
            setValue("aprCal", null);
            setValue("mayCal", null);
            setValue("junCal", null);
            setValue("julCal", null);
            setValue("augCal", null);
            setValue("sepCal", null);
            setValue("octCal", null);
            setValue("novCal", null);
            setValue("decCal", null);
            setValue("assumption", null)
        }
    }, [mode]);

    const onSubmit = async data => {
        let url = mode === "edit" ? `${domain}/api/budgets/budgetItem/${pkId}` : `${domain}/api/budgets/${budgetId}/budgetItem/new`;
        if (costCenterView) {
            url = mode === "edit" ? `${domain}/api/budgets/ccbudgetItem/${pkId}` : `${domain}/api/budgets/${budgetId}/ccbudgetItem/new`;
        }
        const response = await Axios.post(url, data);
        if (response.data && response.data.result === false) {
            if (Array.isArray(response.data.errors) && response.data.errors.length > 0) {
                response.data.errors.forEach((e) => {
                    setError(e.field, { type: "manual", message: e.message });
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.data.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    const state = getValues();
    
    const totalBudget1 = () => {
       
        let tb1 = 0;
        const varPercentage = (state?.enableCpi ? state?.cpi : 0) + (state?.enableLpi ? state?.lpi : 0);
        if (state?.enableCpi || state?.enableLpi) {  
            tb1 =
             applyVariables(Number(state.jan), Number(varPercentage))+
             applyVariables(Number(state.feb), Number(varPercentage))+
             applyVariables(Number(state.mar), Number(varPercentage))+
             applyVariables(Number(state.apr), Number(varPercentage))+
             applyVariables(Number(state.may), Number(varPercentage))+
             applyVariables(Number(state.jun), Number(varPercentage))+
             applyVariables(Number(state.jul), Number(varPercentage))+
             applyVariables(Number(state.aug), Number(varPercentage))+
             applyVariables(Number(state.sep), Number(varPercentage))+
             applyVariables(Number(state.oct), Number(varPercentage))+
             applyVariables(Number(state.nov), Number(varPercentage))+
             applyVariables(Number(state.dec), Number(varPercentage))
         }
         else {
             tb1 =
               toFloat(state?.jan)+
               toFloat(state?.feb)+
               toFloat(state?.mar)+
               toFloat(state?.apr)+
               toFloat(state?.may)+
               toFloat(state?.jun)+
               toFloat(state?.jul)+
               toFloat(state?.aug)+
               toFloat(state?.sep)+
               toFloat(state?.oct)+
               toFloat(state?.nov)+
               toFloat(state?.dec)
            }
            return tb1;

    }

    const totalBudget2 = () => {
        let tb2 = 0;
        tb2 =
        toFloat(state?.jan)+
        toFloat(state?.feb)+
        toFloat(state?.mar)+
        toFloat(state?.apr)+
        toFloat(state?.may)+
        toFloat(state?.jun)+
        toFloat(state?.jul)+
        toFloat(state?.aug)+
        toFloat(state?.sep)+
        toFloat(state?.oct)+
        toFloat(state?.nov)+
        toFloat(state?.dec)
        return tb2;
        
    }
    
    const handleOnMonthlyAmountBlur = useCallback((name, value) => {
        setValue(name.replace("Cal", ""), value);
        clearErrors(name.replace("Cal", ""));
    },[headerDetails]);

    if(flag == true){
        totalBudget1();
        setFlag(false)
    }

    const cpi = "Apply CPI("+headerDetails?.cpi+"%)"
    const lpi = "Apply LPI("+headerDetails?.lpi+"%)"

    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        // console.log('------>', headerDetails?.financialYearStartMonth)
        return (
            <MDBox pt={1} px={3} pb={2} maxWidth={1000}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "expenseTypeId", displayName: "Expense Type", "required": true, dataSource: { type: "static", data: props.expenseTypes || []} }} errorMessage={errors["expenseTypeId"] && errors["expenseTypeId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "costCentreId", displayName: intl.formatMessage({ id: "budgetItemForm.costCentre" }), "required": true, dataSource: { type: "static", data: props.costCentres || [] } }} errorMessage={errors["costCentreId"] && errors["costCentreId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "accountId", displayName: "Account", "required": true, dataSource: { type: "static", data: props.accounts || [] } }} errorMessage={errors["accountId"] && errors["accountId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "vendorId", displayName: "Vendor", dataSource: { type: "static", data: props.vendors || [] } }} errorMessage={errors["yearNameId"] && errors["yearNameId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Textbox watch={watch} setValue={setValue} control={control} fieldDef={{ name: "assumption", displayName: "Assumption" }} textArea={true}/>
                            </MDBox>
                            {/* <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "costPoolId", displayName: "Cost Pool", dataSource: { type: "custom", url: "/api/budgets/lookup/costPool", dependentFields: ["subCostPoolId"] } }} errorMessage={errors["costPoolId"] && errors["costPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "subCostPoolId", displayName: "Sub Cost Pool", dataSource: { type: "custom", url: "/api/budgets/lookup/subCostPool/:costPoolId", parentFields: ["costPoolId"] } }} errorMessage={errors["subCostPoolId"] && errors["subCostPoolId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "towerId", displayName: "Tower", dataSource: { type: "custom", url: "/api/budgets/lookup/tower", dependentFields: ["subTowerId"] } }} errorMessage={errors["towerId"] && errors["towerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "subTowerId", displayName: "Sub Tower", dataSource: { type: "custom", url: "/api/budgets/lookup/subTower/:towerId", parentFields: ["towerId"] } }} errorMessage={errors["subTowerId"] && errors["subTowerId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "solutionOfferingId", displayName: "Solution Offering", dataSource: { type: "custom", url: "/api/budgets/lookup/solutionOffering/:solutionOfferingId"} }} errorMessage={errors["solutionOfferingId"] && errors["solutionOfferingId"].message} />
                            </MDBox>
                            <MDBox mb={2}>
                                <Dropdown watch={watch} setValue={setValue} control={control} fieldDef={{ name: "businessUnitId", displayName: "Business Unit", dataSource: { type: "custom", url: "/api/budgets/lookup/businessUnit/:businessUnitId" } }} errorMessage={errors["businessUnitId"] && errors["businessUnitId"].message} />
                            </MDBox> */}
                        </MDBox>
                        <MDBox>
                            <MDBox mt={2} mb={1}>
                                <MDTypography variant="button" fontWeight="medium">Month-wise Expenditure</MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                                {
                                    getFiscalMonthsArray(headerDetails?.financialYearStartMonth).map((mth, i) => {
                                        return (
                                            <MDBox key={mth + 'box'} m={.7}>
                                                <Float disabled={i < headerDetails?.lockMonths??0} key={mth} watch={watch} setValue={setValue} control={control} fieldDef={{ name: mth.toLowerCase() + "Cal", displayName: mth, variant: "outlined", width: 100 }}  errorMessage={errors[mth.toLowerCase()] && errors[mth.toLowerCase()].message} onBlur={handleOnMonthlyAmountBlur} isDecimal={isDecimal} />
                                            </MDBox>
                                        )
                                    })
                                }
                            </MDBox>
                            {
                                (costCenterView && (headerDetails?.enableCpi || headerDetails?.enableLpi)) && (
                                    <>
                                        <MDBox mt={3}>
                                            <MDTypography variant="button" fontWeight="medium">Apply Variables</MDTypography>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="row">
                                            {
                                                headerDetails?.enableCpi && (
                                                    <MDBox mr={4}>
                                                        <Switch onChange={()=> setFlag(true)} watch={watch} setValue={setValue} control={control} fieldDef={{ name: "enableCpi", displayName: cpi }} errorMessage={errors["enableCpi"] && errors["enableCpi"].message} />
                                                    </MDBox>
                                                )
                                            }
                                            {
                                                headerDetails?.enableLpi && (
                                                    <MDBox>
                                                        <Switch onChange={()=> setFlag(true)} watch={watch} setValue={setValue} control={control} fieldDef={{ name: "enableLpi", displayName: lpi }} errorMessage={errors["enableLpi"] && errors["enableLpi"].message} />
                                                    </MDBox>
                                                )
                                            }
                                        </MDBox>
                                    </>
                                )
                            }
                            {
                                (costCenterView && (headerDetails?.enableCpi || headerDetails?.enableLpi)) && (
                                    <MDBox mt={2} mb={1}>
                                        <MDTypography  variant="button" fontWeight="medium">Total without CPI/LPI : {numeral(totalBudget2()).format('$0,0.00')}</MDTypography>
                                        { state?.enableCpi && !state?.enableLpi ? <MDTypography px={4} variant="button" fontWeight="medium">Total with CPI : {numeral(totalBudget1()).format('$0,0.00')}</MDTypography> :
                                         state?.enableLpi && !state?.enableCpi ? <MDTypography px={4} variant="button" fontWeight="medium">Total with LPI : {numeral(totalBudget1()).format('$0,0.00')}</MDTypography> :
                                         state?.enableCpi && state?.enableLpi ? <MDTypography px={4} variant="button" fontWeight="medium">Total with CPI/LPI : {numeral(totalBudget1()).format('$0,0.00')}</MDTypography> : ""}
                                    </MDBox>
                                )

                            }
                            {
                                (costCenterView && (!headerDetails?.enableCpi && !headerDetails?.enableLpi)) && (
                                    <MDBox mt={2} mb={1}>
                                        <MDTypography variant="button" fontWeight="medium">Total : {numeral(totalBudget2()).format('$0,0.00')}</MDTypography>
                                     </MDBox>
                                )

                            }
                        </MDBox>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};

export default BudgetItemForm;