import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const BudgetHeader = (props) => {
    const { headerDetails } = props;
    return (
        <MDBox display="flex">
            <MDBox display="flex" flexDirection="column">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["name"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Name</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["yearName.name"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Year</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["statusName"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Status</MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2}>{headerDetails["type"]}</MDTypography>
                <MDTypography component="span" variant="subtitle2">Type</MDTypography>
            </MDBox>
            {
                headerDetails?.enableCpi === true && headerDetails?.enableCpi !== 0 && (
                    <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} textAlign="center">{headerDetails["cpi"]}</MDTypography>
                        <MDTypography component="span" variant="subtitle2">CPI %</MDTypography>
                    </MDBox>
                )
            }
            {
                headerDetails?.enableLpi === true && headerDetails?.enableLpi !== 0 && (
                    <MDBox display="flex" flexDirection="column" ml={4} pl={2} borderLeft="1px solid #adadad">
                        <MDTypography component="span" fontWeight={"medium"} lineHeight={1.2} textAlign="center">{headerDetails["lpi"]}</MDTypography>
                        <MDTypography component="span" variant="subtitle2">LPI %</MDTypography>
                    </MDBox>
                )
            }
            <MDBox display="flex" flexDirection="column" ml={10} pl={2}>
                {/* <FormControlLabel
                    control={<MDSwitch name={"name"} checked={false} onChange={(_, checked) => { }} />}
                    label={"Display calculated amounts"}
                    labelPlacement="end"
                /> */}
                {/* <MDTypography component="span" variant="subtitle2">Display calcualted amounts</MDTypography> */}
            </MDBox>
        </MDBox>
    )
};

export default BudgetHeader;