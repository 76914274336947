import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
// import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import { useImmer } from "use-immer";
import { normalizeCurrency } from "utils/table";
import MDAvatar from "components/MDAvatar";
import moment from "moment";
import { NotificationType } from "constants";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import { useState, useEffect } from "react";
import _ from "lodash";


const OfferingMappingView = (props) => {
    const { showAlert, showPrompt, showSnackbar } = useYADialog();
    const { yearFilter, yearFilterName, monthFilter, monthFilterName, containerHeight,typeFilter, setAction, download, setRefresh, action, chargeBackMonthly, serverSideDataPaging, serverSideDataFetching } = props;
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    }
    const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false
    const [ pgIndx, setPgIndx] = useState( serverSideDataPaging && serverSideDataFetching ? 0 : null)
    const [ pageSize, setPageSize] = useState( serverSideDataPaging && serverSideDataFetching ? 50 : null)
    const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
    const [ totalItems, setTotalItems ] = useState(null)
    const [ loading, setLoading ] = useState(false);
    const [ reload, setReload ] = useState(false);
    const [ _err, setError ] = useState(null);
    const [ uploadedFiles, setUploadedFiles ] = useState([])

    let handlePageOption = () => {
        onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
    }

    const onPageOptionsChange = (pageOption) => {
        setLoading(true)
        setPgIndx(pageOption.currentPage);
        setPageSize(pageOption.pageSize)
    }

    const reloadData = () => {
        setReload(Math.random())
    }

    useEffect( async () => {
        if (serverSideDataPaging && serverSideDataFetching) {
            setLoading(true);
            if (yearFilter && monthFilter) {
            var [err, response] = typeFilter == "Spend" && action !== 'add' ? await fetchRequest.get(`/api/dataflow/solution/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`) : await fetchRequest.post(`/api/dataflow/solutionRulesBudget/${yearFilter}/${monthFilter}?type=${typeFilter.toLowerCase()}&&allocated=${action === 'add' ? false : true}&pgIndx=${pgIndx}&pageSize=${pageSize}`);
            if (err) {
                setError(err);
            } else {
                const { data, totalItems, totalPages }  = response
                if (data && Array.isArray(data) && data.length > 0) {
                    setUploadedFiles(data)
                    setTotalItems(totalItems)
                    setTotalNoOfPages(totalPages)
                } else {
                    setUploadedFiles([]);
                }
            }
            setLoading(false);
            }
        }
      }, [reload, yearFilter, monthFilter, pageSize, pgIndx]);

      useEffect( async () => {
        if (!serverSideDataPaging && !serverSideDataFetching) {
            setLoading(true);
            if (yearFilter && monthFilter) {
            var [err, data] = typeFilter == "Spend" && action !== 'add' ? await fetchRequest.get(`/api/dataflow/solution/${yearFilter}/${monthFilter}`) : await fetchRequest.get(`/api/dataflow/solutionRulesBudget/${yearFilter}/${monthFilter}?type=${typeFilter.toLowerCase()}&&allocated=${action === 'add' ? false : true}`);
            if (err) {
                setError(err);
            } else {
                if (data && Array.isArray(data) && data.length > 0) {
                    setUploadedFiles(data)
                    setTotalItems(totalItems)
                } else {
                    setUploadedFiles([]);
                }
            }
            setLoading(false);
            }
        }
      }, [reload, yearFilter, monthFilter]);


    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === "capabilityOffering") {
            reloadData();
        }
    });

    let columns = []

    if (typeFilter == "Spend") {

     columns = [
        { Header: "Asset Type", accessor: "assetType", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</MDTypography> } },
        { Header: "Asset Code", accessor: "capabilityCode", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Asset Name", accessor: "applicationName", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value}</MDTypography> } },
        { Header: "Offering Code", accessor: "offeringCode", dataType: "textbox", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Offering Name", accessor: "offeringName", dataType: "textbox", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Portion", accessor: "rPortion", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value.toFixed(2)}</MDTypography> } },
        { Header: "Amount", accessor: "spend", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format("$0,0")}</MDTypography> } },
        { Header: "Source", accessor: "source", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{(value) ? "Rule" : "File"}</MDTypography> } },
        { Header: "Created On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}</MDTypography> } },
        { Header: "Created By", accessor: "name", disableFilters: true, Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography> } } },

    ]
}
else {
    columns = [
        { Header: "Expense Type", accessor: "expenseType__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Code", accessor: "account__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Account Name", accessor: "account__name", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Code", accessor: "costCentre__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Center Name", accessor: "costCentre__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Code", accessor: "vendor__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Vendor Name", accessor: "vendor__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Cost Pool", accessor: "costPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value}</MDTypography> } },
        { Header: "Sub Cost Pool", accessor: "subCostPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Tower", accessor: "tower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Sub Tower", accessor: "subTower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Solution Offering", accessor: "solutionOffering__name", hidden: action === 'add' ? true : false, dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: typeFilter, accessor: "amount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0')}</MDTypography> } },
    ]
}

const handleAction = () => {
    setAction('choose')
}

const handleDownload = () => {
    download(yearFilter, monthFilter, yearFilterName, monthFilterName)
}

const handleDeleteAll = () => {
    showPrompt("Delete", "Are you sure you want to delete " + 'solution' + " mapping for - [ " + monthFilterName + " " + yearFilterName + " ]", async () => {
      let [err, data] = await fetchRequest.delete(`/api/dataflow/offeringRules/${yearFilter}/${monthFilter}`)
      if (err) {
        if (err.data?.message) {
          showSnackbar(err.data?.message, "error")
          setRefresh(Math.random())
          reloadData()
        } else {
          console.error(err)
          showAlert("Delete", "Something went wrong. Contact your administrator.");
        }
      }
      else if (data) {
        showSnackbar(data, "success")
        setRefresh(Math.random())
        reloadData()
      }
    });
}

const getMenuActions = () => {
    let actions = [];
    if (typeFilter === "Spend") {
        actions.push({ label: "Add", onClick: handleAction, disabled: chargeBack })
        actions.push({ label: "Delete", onClick: handleDeleteAll, disabled: chargeBack })
    }
    actions.push({ label: "Download", onClick: handleDownload })
    return actions;
}

    if (loading) return <YASkeleton variant="loading" />

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    if (_err)
        console.error(_err)
    const rows = uploadedFiles?.map((row) => {
        let r = {};
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });

    return (
        <>
            <DataTable
                table={{ columns, rows }}
                containerMaxHeight={containerHeight??500}
                showTotalEntries={true}
                newStyle1={true}
                noEndBorder
                menuActionRender={getMenuActions()}
                isSorted={true}
                entriesPerPage={true}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                loading={loading}
                serverSideDataPaging={serverSideDataPaging}
                serverSideDataFetching={serverSideDataFetching}
                pgIndx={pgIndx}
                setPgIndx={setPgIndx}
                onPageOptionsChange={onPageOptionsChange}
                pageSizeVal={pageSize}
                totalNoOfPages={totalNoOfPages}
                totalItems={totalItems}
                handlePageOption={handlePageOption}

            >
            </DataTable>
        </>
    )
}

export default OfferingMappingView;